import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "keen-slider/keen-slider.min.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { RecoilRoot } from "recoil";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, createTheme, ThemeProvider } from "@mui/material";

const App = lazy(() => import("./App"));
const ErrorPage = lazy(() => import("./pages/error"));
const HomePage = lazy(() => import("./pages/home"));
const LoginPage = lazy(() => import("./pages/login"));
const SignupPage = lazy(() => import("./pages/signup"));
const DashboardPage = lazy(() => import("./pages/dashboard"));
const HistoryPage = lazy(() => import("./pages/history"));
const SettingPage = lazy(() => import("./pages/setting"));
const OrganizationPage = lazy(() => import("./pages/organization"));
const AppRenderPage = lazy(() => import("./pages/AppRender"));
const AppStudioPage = lazy(() => import("./pages/AppStudio"));
const AppEditPage = lazy(() => import("./pages/AppEdit"));
const DataPage = lazy(() => import("./pages/data"));
const Discover = lazy(() => import("./pages/discover"));
const AppStudioWrapperPage = lazy(() => import("./pages/AppStudioWrapper"));
const UserManagemanetPage = lazy(() => import("./pages/UserManagemanetWrapper"));


const defaultTheme = createTheme({
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

let router = null;

const root = ReactDOM.createRoot(document.getElementById("root"));

function getCookieValue(name) {
  const nameString = name + "=";
  const value = document.cookie.split(';').find(item => item.trim().startsWith(nameString));
  if (value) {
    return value.split('=')[1];
  }
  return null;
}

  let user_type = getCookieValue('user_type')
  console.log("App initialization tasks...",user_type);
  router = createBrowserRouter([
    {
      path: "/",
      element: (
        <App>
          <AppStudioWrapperPage />
        </App>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/playground",
      element: (
        <App>
          <HomePage isSharedPageMode={false} />
        </App>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/user-management",
      element: (
        <App>
          <UserManagemanetPage/>
        </App>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/apps",
      element: (
        <App>
          <AppStudioWrapperPage />
        </App>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/apps/templates/:appTemplateSlug",
      element: (
        <App>
          <AppStudioPage />
        </App>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/apps/:appTypeSlug/create",
      element: (
        <App>
          <AppEditPage />
        </App>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/apps/:appId",
      element: (
        <App>
          <AppEditPage />
        </App>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/apps/:appId/preview",
      element: (
        <App>
          <AppEditPage page="preview" />
        </App>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/apps/:appId/template",
      element: (
        <App>
          <AppEditPage page="template" />
        </App>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/apps/:appId/editor",
      element: (
        <App>
          <AppEditPage page="editor" />
        </App>
      ),
      errorElement: <ErrorPage />,
    },
    // {
    //   path: "/apps/:appId/editor",
    //   element: (
    //     <App>
    //       <AppEditPage page="editor" />
    //     </App>
    //   ),
    //   errorElement: <ErrorPage />,
    // },
    {
      path: "/apps/:appId/editor",
      element: (
        <App>
          <AppEditPage page="editor" />
        </App>
      ),
      errorElement: <ErrorPage />,
    },
    {
      //FIXME: added bu UX
      path: "/apps/:appId/websitechat",
      element: (
        <App>
          <AppEditPage page="websitechat" />
        </App>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/apps/:appId/history",
      element: (
        <App>
          <AppEditPage page="history" />
        </App>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/apps/:appId/tests",
      element: (
        <App>
          <AppEditPage page="tests" />
        </App>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/apps/:appId/versions",
      element: (
        <App>
          <AppEditPage page="versions" />
        </App>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/apps/:appId/integrations/website",
      element: (
        <App>
          <AppEditPage page="integrations/website" />
        </App>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/apps/:appId/integrations/api",
      element: (
        <App>
          <AppEditPage page="integrations/api" />
        </App>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/apps/:appId/integrations/slack",
      element: (
        <App>
          <AppEditPage page="integrations/slack" />
        </App>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/apps/:appId/integrations/discord",
      element: (
        <App>
          <AppEditPage page="integrations/discord" />
        </App>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/apps/:appId/integrations/twilio",
      element: (
        <App>
          <AppEditPage page="integrations/twilio" />
        </App>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/apps/:appId/preview",
      element: (
        <App>
          <AppEditPage page="preview" />
        </App>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/app/:publishedAppId/:embed?/:chatBubble?",
      element: <AppRenderPage headless={true} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/user/:publishedAppId",
      element: (
        <App>
          <AppRenderPage/>
        </App>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/hub",
      element: (
        <App>
          <Discover />
        </App>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/s/:shareId",
      element: (
        <App>
          <HomePage isSharedPageMode={true} />
        </App>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/dashboard",
      element: (
        <App>
          <DashboardPage />
        </App>
      ),
    },
    {
      path: "/history",
      element: (
        <App>
          <HistoryPage />
        </App>
      ),
    },
    {
      path: "/settings",
      element: (
        <App>
          <SettingPage />
        </App>
      ),
    },
    {
      path: "/organization",
      element: (
        <App>
          <OrganizationPage />
        </App>
      ),
    },
    {
      path: "/data",
      element: (
        <App>
          <DataPage />
        </App>
      ),
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/signup",
      element: <SignupPage />,
    },
  ]);


  if (user_type == 'user'){
      router = createBrowserRouter([
        {
          path: "/",
          element: (
            <App>
              <AppStudioWrapperPage />
            </App>
          ),
          errorElement: <ErrorPage />,
        },
        {
          path: "/apps",
          element: (
            <App>
              <AppStudioWrapperPage />
            </App>
          ),
          errorElement: <ErrorPage />,
        },
        {
          path: "/app/:publishedAppId/:embed?/:chatBubble?",
          element: <AppRenderPage headless={true} />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/user/:publishedAppId",
          element: (
            <App>
              <AppRenderPage/>
            </App>
          ),
          errorElement: <ErrorPage />,
        },
        {
          path: "/dashboard",
          element: (
            <App>
              <DashboardPage />
            </App>
          ),
        },
        {
          path: "/history",
          element: (
            <App>
              <HistoryPage />
            </App>
          ),
        },
        {
          path: "/login",
          element: <LoginPage />,
        }
      ]);
  }

  root.render(
    <React.StrictMode>
      <RecoilRoot>
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              minHeight: "100vh",
              background:
                window.location.href.endsWith("/embed") ||
                window.location.href.endsWith("/embed/chatBubble")
                  ? "transparent"
                  : "#f5f5f5",
            }}
          >
            <RouterProvider
              router={router}
              fallbackElement={<CircularProgress />}
            />
          </Box>
        </ThemeProvider>
      </RecoilRoot>
    </React.StrictMode>,
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

